export const environment = {
  production: false,
  casdoorConfig: {
    serverUrl: 'https://casdoor.dev.nimbuz.cloud',
    clientId: 'b8e9bfbcf2002d7cf6b2',
    appName: 'nimbuz-dev',
    organizationName: 'built-in',
    redirectPath: '/'
},
gitlab: {
  clientId: '66d8ed03ba256e0c95fb4c2384f5be16737636ca54c78446d427b3bd985bf884',
  redirectUri: 'http://localhost:4200/dashboard',
  authUrl: 'https://gitlab.com/oauth/authorize',
  tokenUrl: 'https://gitlab.com/oauth/token',
},

  apiUrl: 'https://api.dev.nimbuz.cloud/core/v1/environments',
  legacyUrl: 'https://api.dev.nimbuz.cloud/core/v1',
  loginUrl: 'https://api.dev.nimbuz.cloud/core/login',
  projectsApiUrl: 'https://api.dev.nimbuz.cloud/project-management/v1/projects',
  projectsBaseUrl: 'https://api.dev.nimbuz.cloud/project-management/v1',
  usermanagementApiUrl: 'https://api.dev.nimbuz.cloud/user-management',
  deploymentManagement: 'https://api.dev.nimbuz.cloud/deployment-management/v1',
  wss: 'wss://api.dev.nimbuz.cloud/job-executor'
};
